<!--房屋可视化 -->
<template>
  <div style="width: 100%; height: 100%">
    <div style="width: 100%; height: 5%">
      <div class="container">
        <div class="span1" @click="callback">
          <img src="../../../../../../assets//community/rollback.png" alt="" />
          <span>返回上层</span>
        </div>
        <div class="span2">
          <img src="../../../../../../assets/images/house.png" alt="" />
          <span>{{ this.gridPname }}</span
          ><span>/</span>
          <span>{{ this.gridName }}</span>
          <span class="unitName">{{ this.blockCode }}栋</span>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 2%"></div>
    <div style="width: 100%; height: 93%">
      <div style="width: 100%; height: 100%; display: flex">
        <!-- 左边 -->
        <div style="width: 40%; height: 100%">
          <div style="width: 100%; height: 100%">
            <div class="develop-footer">
              <div class="develop">
                <span>&nbsp;房屋信息</span>
                <div style="color: #2a5cff" @click="EditBuildinginformation">
                  <a-icon type="edit" />编辑房屋信息
                </div>
                <el-dialog
                  title="编辑房屋信息"
                  width="1000px"
                  :visible.sync="BuildingVisible"
                  :z-index="12"
                  :destroy-on-close="true"
                  :close-on-click-modal="false"
                >
                  <EditBuilding
                    ref="position"
                    @close="BuildingVisible = false"
                  />
                </el-dialog>
              </div>
              <div
                style="
                  height: 90%;
                  width: 100%;
                  padding-top: 2%;
                  padding-bottom: 2%;
                  margin-left: 2%;
                "
              >
                <a-descriptions title="" layout="vertical">
                  <a-descriptions-item label="房屋状态"
                    >已住</a-descriptions-item
                  >
                  <a-descriptions-item label="房屋用途"
                    >自住</a-descriptions-item
                  >
                  <a-descriptions-item label="房屋面积"
                    >91m</a-descriptions-item
                  >
                  <a-descriptions-item label="房间数">3 </a-descriptions-item>
                  <a-descriptions-item label="房屋朝向">
                    南北</a-descriptions-item
                  >
                  <a-descriptions-item label="所属网格"
                    >网格1
                  </a-descriptions-item>
                  <a-descriptions-item label="所属小区">
                    杨黄小区</a-descriptions-item
                  >
                </a-descriptions>
              </div>
            </div>
          </div>
        </div>
        <!-- 右边 -->
        <div class="right-unit">
          <div style="width: 90%; height: 100%">
            <div class="develop2">
              <span class="home">
                <div class="info">
                  <img src="../../../../../../assets/images/hose.png" alt="" />
                  <span>2单元</span>
                  <span>2层</span>
                  <span>201</span>
                </div>
                <div class="residents" style="color: #e6a23c">
                  <a-icon type="info-circle" />共有6人居住
                </div>
              </span>
              <div style="color: #2a5cff" @click="AddInform">
                <a-icon type="edit" />添加居住人员
              </div>
              <el-dialog
                title="添加居住人员"
                width="800px"
                :visible.sync="UnitVisible"
                :z-index="12"
                :destroy-on-close="true"
                :close-on-click-modal="false"
              >
                <AddInform ref="position" @close="UnitVisible = false" />
              </el-dialog>
            </div>

            <div class="footer">
              <div class="house">
                <div class="house-family" v-for="index in 6" :key="index">
                  <div style="width: 100%; height: 100%">
                    <div class="top">
                      <div class="top-left">
                        <div class="top-text">
                          <span class="label">姓名:</span>
                          <span class="value">张三</span>
                        </div>
                        <div class="top-text">
                          <span class="label">身份证号:</span>
                          <span class="value">173489002</span>
                        </div>
                        <div class="top-text">
                          <span class="label">联系方式:</span>
                          <span class="value">167390222</span>
                        </div>
                        <div class="top-text">
                          <div class="blue">常住人口</div>
                          <div class="red">党员</div>
                        </div>
                      </div>
                      <div class="top-right">
                        <img
                          src="../../../../../../assets/images/page403.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="footer" @click="showWatchPeople">
                      <div class="button">查看详情</div>
                    </div>
                    <watchPeople
                      :visible="isWatchPeopleVisible"
                      @close="isWatchPeopleVisible = false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      
      <script>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';

import api from "../api";
import EditBuilding from "./components/editunit.vue";
import AddInform from "../Common/practical.vue";
import watchPeople from "./components/watchPeople.vue";
export default {
  components: { EditBuilding, AddInform, watchPeople },
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      api,
      gridId: "",
      gridName: "",
      gridPname: "",
      blockCode: "",
      houseType: "",
      managerName: "",
      managerPhone: "",
      unitName: "",
      dyCount: "",
      unitCount: "",
      actualPopulation: "",
      localResidentCount: "",
      flowResidentCount: "",
      data: [],
      unitNames: [], // 存储所有单元的名称
      peopleData: [],
      firstFourData: [],
      lastThreeData: [],
      aggregateData1: [],
      aggregateData2: [],
      chartimport: null,
      chartdevelop: null,
      UnitVisible: false,
      BuildingVisible: false,
      isWatchPeopleVisible: false,
      ida: this.$route.query.id,
      id: this.$route.query.recordId,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    showWatchPeople() {
      this.isWatchPeopleVisible = true;
    },
    EditBuildinginformation() {
      this.BuildingVisible = true;
    },
    AddInform() {
      this.UnitVisible = true;
    },
    //返回
    callback() {
      this.$router.push({
        path: "/views/communityGovernance/housingList/buildingList/Unitfloor/index.vue",
        query: {
          title: "房屋管理",
          recordId: this.ida,
        },
      });
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
      <style lang='less' scoped>
.container {
  width: 100%;
  // height: 0.15rem;
  background: rgba(188, 204, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer; /* 添加指针样式，表示可点击 */
}
.span1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 1%;
  img {
    margin-right: 0.02rem;
  }
}
.span2 {
  display: flex;
  align-items: center;
  padding-right: 1.5%;
  img {
    margin-left: 0.5%;
  }
  span {
    margin-left: 1%;
    margin-right: 1%;
    font-weight: 700;
    font-size: 0.1rem;
    color: #2d3138;
    line-height: 18px;
    text-align: left;
    white-space: nowrap;
  }
  .unitName {
    color: #556bff;
    margin-left: 0.5%;
    font-weight: 700;
    font-size: 0.1rem;
    line-height: 18px;
    text-align: left;
  }
}
.develop-header {
  width: 100%;
  height: 30%;
}
.develop-total {
  width: 100%;
  height: 24%;
}
.develop-footer {
  width: 100%;
  height: 18%;
}
.develop {
  border-left: 4px solid #556bff;
  font-size: 0.09rem;
  font-weight: bold;
  color: black;
  line-height: 100%;
  margin-bottom: 1%;
  display: flex;
  justify-content: space-between;
  cursor: pointer; /* 添加指针样式，表示可点击 */
}
.develop2 {
  //   border-left: 4px solid #556bff;
  font-size: 0.09rem;
  font-weight: bold;
  color: black;
  line-height: 100%;
  margin-bottom: 1%;
  display: flex;
  justify-content: space-between;
  cursor: pointer; /* 添加指针样式，表示可点击 */
}
.heaved {
  width: 0.12rem;
  height: 0.09rem;
  margin-right: 10px;
  background: #faecd8;
  border-top: 2px solid #e6a23c;
}
.leaved {
  width: 0.12rem;
  height: 0.09rem;
  margin-right: 10px;
  background: #dce5ff;
  border-top: 2px solid #556bff;
}
.exist {
  width: 1.02rem;
  height: 0.5rem;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(53, 65, 97, 0.15);
  border-radius: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.people {
  width: 1.59rem;
  height: 0.5rem;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(53, 65, 97, 0.15);
  border-radius: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.text {
  font-weight: 700;
  font-size: 22px;
  color: #2d3138;
  line-height: 28px;
  text-align: left;
}
.exit-title {
  display: flex;
  flex-direction: column;
}
.title {
  font-weight: 400;
  font-size: 14px;
  color: #7d8da6;
  line-height: 22px;
  text-align: left;
}
.message {
  width: 33%;
  height: 18%;
  margin-bottom: 1%;
  display: flex;
  flex-direction: column;
}
.message-title {
  font-weight: 400;
  font-size: 14px;
  color: #7d8da6;
  line-height: 18px;
  text-align: left;
}
.message-tt {
  font-weight: 400;
  font-size: 14px;
  color: #2d3138;
  line-height: 18px;
  text-align: left;
  margin-top: 10px;
}
.footer-message {
  display: flex;
  // justify-content: space-around;
}
.footer-footer {
  display: flex;
  // justify-content: space-around;
}
/deep/.ant-descriptions-item-content {
  display: table-cell;
  font-weight: 400;
  font-size: 14px;
  color: #2d3138;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
/deep/.ant-descriptions-item-label {
  font-weight: 400;
  font-size: 14px;
  color: #7d8da6;
  line-height: 18px;
  text-align: left;
}
.collapse-container {
  display: flex;
  flex-wrap: wrap; /* 让元素自动换行 */
}
.levedheave {
  width: 0.4rem;
  cursor: pointer;
  height: 0.2rem;
  background-color: #f0f0f0;
  margin-right: 10px; /* 可以根据需要调整元素之间的间距 */
  margin-bottom: 10px; /* 可以根据需要调整元素之间的间距 */
  display: flex; /* 让内容垂直居中 */
  justify-content: space-around;
  align-items: center; /* 让内容垂直居中 */
  background: #faecd8;
  border-top: 2px solid #e6a23c;
}
.deserted {
  width: 0.4rem;
  cursor: pointer;
  height: 0.2rem;
  background-color: #f0f0f0;
  margin-right: 10px; /* 可以根据需要调整元素之间的间距 */
  margin-bottom: 10px; /* 可以根据需要调整元素之间的间距 */
  display: flex; /* 让内容垂直居中 */
  justify-content: space-around;
  align-items: center; /* 让内容垂直居中 */
  background: #dce5ff;
  border-top: 2px solid #556bff;
}
.close-icon {
  margin-left: 5px; /* 调整图标与文本之间的间距 */
  cursor: pointer; /* 添加指针样式，表示可点击 */
}
.storey {
  font-weight: 700;
  font-size: 0.07rem;
  color: rgb(45, 49, 56);
  display: flex;
  justify-content: center;
  align-items: center;
}
.household {
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
}
/deep/.el-dialog {
  border-radius: 20px !important;
}
.right-unit {
  width: 60%;
  height: 100%;
  display: flex;
  overflow-y: auto;
  justify-content: flex-end;
  .top {
    width: 100%;
    .top-unit {
      display: flex;
      margin: 2%;
      align-items: center;
      span {
        font-weight: bold;
        font-size: 13px;
        color: #2d3138;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        white-space: nowrap;
      }
      div {
        background: #f2f5ff;
        border-radius: 4px;
        margin-left: 1%;
        margin-right: 1%;
        width: 7%;
        height: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 13px;
        color: #2d3138;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        white-space: nowrap;
        padding: 0.5%; /* 调整内边距 */
        cursor: pointer; /* 添加指针样式，表示可点击 */
      }
    }
    .top-building {
      display: flex;
      flex-wrap: wrap; /* 允许多行排列 */
      margin: 2%;
      align-items: center;
    }

    .top-building span {
      font-weight: bold;
      font-size: 13px;
      color: #2d3138;
      line-height: 16px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      white-space: nowrap;
    }

    .top-building div {
      background: #f2f5ff;
      border-radius: 4px;
      margin: 1%; /* 调整间距 */
      padding: 0.5%; /* 调整内边距 */
      width: 7%;
      height: 2%;
      font-weight: bold;
      font-size: 13px;
      color: #2d3138;
      line-height: 16px;
      text-align: center; /* 更改为居中对齐 */
      font-style: normal;
      text-transform: none;
      white-space: nowrap;
      cursor: pointer; /* 添加指针样式，表示可点击 */
    }
  }
  .footer {
    width: 100%;
    .housing {
      font-size: 0.09rem;
      font-weight: bold;
      color: #e6a23c;
      line-height: 17px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      display: flex;
      justify-content: space-between;
    }
    .house {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .house-family {
        width: 45%; /* 调整每个div的宽度，使其能够容纳更多个div */
        height: 200px;
        background: #ffffff;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin: 1%; /* 调整间距 */
        padding: 2% 4%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .top {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          height: 80%;

          .top-left {
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            height: 100%; /* 确保子元素间距按百分比生效 */
            width: 70%;
            .top-text {
              display: flex;
              align-items: center;
              margin-bottom: 7%;
              .label {
                min-width: 50px; /* 根据需要调整标签的最小宽度，确保对齐 */
                text-align: left; /* 标签内容右对齐 */
                margin-right: 15px; /* 标签和值之间的间距 */
                color: #7d8da6;
              }
              .value {
                flex: 1;
              }
            }
            .blue {
              font-weight: 700;
              font-size: 13px;
              color: #2a5cff;
              line-height: 17px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }
            .red {
              font-weight: 700;
              font-size: 13px;
              color: #f95a5a;
              line-height: 17px;
              text-align: left;
              font-style: normal;
              text-transform: none;
              margin-left: 20px;
            }
          }
          .top-right {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%; /* 确保子元素间距按百分比生效 */
            width: 30%;

            img {
              max-width: 100%; /* 确保图片不会超出容器 */
              height: auto;
            }
          }
        }
        .footer {
          margin-top: 2%;
          width: 100%;
          height: 17%;
          .button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            border: 1px dashed #2a5cff;
            font-weight: 400;
            font-size: 13px;
            color: #2a5cff;
            line-height: 17px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          }
        }
      }
    }
  }
}
.home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20%;
  font-weight: 400;
  font-size: 16px;
  color: #2d3138;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.residents {
  font-weight: 400;
  font-size: 13px;
  color: #e6a23c;
  line-height: 17px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.info,
.residents {
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 5%;
}
</style>